body {
    background: linear-gradient(135deg, #011a34 0%, #006693 100%);
    font-family: "Quicksand", sans-serif;
    color: #fff;
    height: 100vh;
    text-align: center;
    h1 {
        font-size: 20px;
        @media (min-width: 375px) {
            font-size: 23px;
        }
        @media (min-width: 425px) {
            font-size: 25px;
        }
        @media (min-width: 768px) {
            font-size: 29px;
        }
        @media (min-width: 1024px) {
            font-size: 39px;
        }
        @media (min-width: 1366px) {
            font-size: 47px;
        }
    }
}

.header {
    .logo {
        background: url('/img/logo-center.png') no-repeat center;
        height: 100px;
        background-size: contain;
        @media (min-width: 375px) {
            height: 120px;
        }
        @media (min-width: 425px) {
            height: 140px;
        }
        @media (min-width: 1024px) {
            height: 200px;
        }
    }
}

.image {
    img {
        width: 100%;
    }
}

.url {
    h1 {
        color: #006693;
        position: absolute;
        margin-top: -19px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: bold;
        font-size: 12px;
        @media (min-width: 375px) {
            margin-top: -21px;
        }
        @media (min-width: 425px) {
            margin-top: -25px;
            font-size: 15px;
        }
        @media (min-width: 768px) {
            margin-top: -45px;
            font-size: 25px;
        }
        @media (min-width: 1024px) {
            margin-top: -65px;
            font-size: 40px;
        }
        @media (min-width: 1366px) {
            margin-top: -75px;
        }
    }
}

.footer {
    .heading {
        h1 {
            font-size: 20px;
            @media (min-width: 375px) {
                font-size: 23px;
            }
            @media (min-width: 425px) {
                font-size: 25px;
            }
            @media (min-width: 768px) {
                font-size: 29px;
            }
            @media (min-width: 1024px) {
                font-size: 33px;
            }
            @media (min-width: 1366px) {
                font-size: 41px;
            }
        }
    }
    .info {
        padding-top: 20px;
        div {
            display: inline-block;
            padding: 15px;
            height: 15px;
            margin: 5px 0px;
            width: 100%;
            @media (min-width: 768px) {
                width: auto;
            }
        }
        a {
            color: #fff;
            font-size: 17px;
            &:hover {
                text-decoration: none;
            }
            @media (min-width: 768px) {
                font-size: 22px;
            }
        }
        h3 {
            margin: 0px;
            line-height: 2px;
        }
        .left {
            @media (min-width: 768px) {
                text-align: right;
            }
        }
        .center {
            border-left: 2px solid #fff;
            border-right: 2px solid #fff;
        }
        .right {
            @media (min-width: 768px) {
                text-align: left;
            }
        }
    }
}